import * as Utils from './utils';

'use strict';

const deadline = 'August 22 2028 00:00:00 GMT+0200';

const initialized = new WeakMap();

const getTimeRemaining = (endtime) => {
    const total = Date.parse(endtime) - Date.parse(new Date());
    const seconds = Math.floor( (total/1000) % 60 );
    const minutes = Math.floor( (total/1000/60) % 60 );
    const hours = Math.floor( total/(1000*60*60) );
  
    return {
        total,
        hours,
        minutes,
        seconds
    };
};

Utils.foreachElement('.countdown p:last-child', (numbers) => {
    if (initialized.has(numbers)) {
        return;
    }

    initialized.set(numbers, true);

    const update = (function update(t) {
        numbers.innerHTML = String(t.hours).padStart(2, '0') + ':' + String(t.minutes).padStart(2, '0') + ':' + String(t.seconds).padStart(2, '0');
        return update;
    })(getTimeRemaining(deadline));

    const timeinterval = setInterval(() => {
        const t = getTimeRemaining(deadline);
        update(t);

        if (t.total <= 0) {
            clearInterval(timeinterval);
        }
    }, 1000);
});
