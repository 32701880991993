import * as Utils from './utils';

'use strict';

const initialized = new WeakMap();

Utils.foreachElement('.menu-button', (button) => {
    if (initialized.has(button)) {
        return;
    }

    initialized.set(button, true);

    ['touchend', 'mouseup'].forEach((e) => {
        button.addEventListener(e, (event) => {
            event.preventDefault();
            document.body.classList.toggle('show-menu');    
            return false;
        });
    });
});

window.addEventListener('pagehide', () => {
    document.body.classList.remove('show-menu');    
});

document.documentElement.addEventListener('turbo:before-render', () => {
    document.body.classList.remove('show-menu');
});

Utils.foreachElement('#header', (header) => {
    if (initialized.has(header)) {
        return;
    }

    initialized.set(header, true);

    const body = document.body;
    let lastScrollPos = null;
    let timeout = null;

    (function checkScrollPos() { 
        if (window.scrollY > 0) {
            document.body.classList.add('scroll--scrolled');
        } else {
            document.body.classList.remove('scroll--scrolled');
        }

        if (null !== lastScrollPos && lastScrollPos !== window.scrollY) {
            const delta = window.scrollY - lastScrollPos;

            body.classList.add('scroll--moving');
            body.classList.remove('scroll--stop');
            body.classList.remove('scroll--down');
            body.classList.remove('scroll--up');

            if (delta >= 0) {
                body.classList.add('scroll--down');
            } else {
                body.classList.add('scroll--up');
            }

            clearTimeout(timeout);
    
            timeout = setTimeout(() => {
                body.classList.remove('scroll--moving');
                body.classList.add('scroll--stop');
            }, 500);
        }

        if (null !== lastScrollPos || window.scrollY > 0) {
            lastScrollPos = window.scrollY;
        }

        window.requestAnimationFrame(checkScrollPos);
    })();
});
